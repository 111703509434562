// import autocomplete from '@/components/autocomplete/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
// import buyFlowMixin from '@/mixins/buy-flow'

export default {
    name: 'delivery-nova-poshta',
    // mixins: [buyFlowMixin],
    data() {
        return {
            town: 'Київ',
            townRef: '',
            department: '',
            isValid: false,
            isValidTown: false,
            isValidDepartment: false,
            building: '',
            flat: ''
        }
    },
    components: {
        // autocomplete
    },
    computed: {
        ...mapGetters({
            towns: 'order/towns',
            departments: 'order/departments'
        }),
    },
    watch: {

        town() {
            const currentTown = this.towns.find(e => e.Description === this.town)
            this.department = ''

            if (!currentTown) {
                return
            }

            if (this.town === currentTown.Description) {
                this.onRequestDepartments('')
            } else {
                this.setDepartments([])
            }
            this.changeAddress({town: this.town, department: this.department, building: this.building, flat: this.flat})

        },
        department() {
            this.checkValidData()
            this.changeAddress({town: this.town, department: this.department, building: this.building, flat: this.flat})
        },
        building() {
            this.changeAddress({town: this.town, department: this.department, building: this.building, flat: this.flat})
        },
        flat() {
            this.changeAddress({town: this.town, department: this.department, building: this.building, flat: this.flat})
        }
    },
    created() {
        this.onRequestTown(this.town)
        this.onRequestDepartments('')
    },
    methods: {
        ...mapActions({
            fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
            fetchDepartments: 'order/NOVA_POSHTA_GET_DEPARTMENS',
        }),
        ...mapMutations({
            setDepartments: 'order/NOVA_POSHTA_SET_DEPARTMENS',
            changeAddress: 'order/CHANGE_ADDRESS_COURIER',
        }),
        onRequestTown(val) {
            const formData = JSON.stringify({
                "modelName": "Address",
                "calledMethod": "getCities",
                "methodProperties": {
                    "FindByString": val,
                    "Language": "ua"
                },
                "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
            })
            this.fetchTowns(formData).then(() => {
                const currentTown = this.towns.find(e => e.Description === this.town)
                this.department = ''
                this.townRef = currentTown.Ref
                if (!currentTown) {
                    return
                }

                if (this.town === currentTown.Description) {
                    this.onRequestDepartments('')
                } else {
                    this.setDepartments([])
                }
                this.checkValidData()
            })
        },
        onRequestDepartments(val) {

            const formData = JSON.stringify({
                "modelName": "Address",
                "calledMethod": "getStreet",
                "methodProperties": {
                    "CityRef": this.townRef,
                    "FindByString": val,
                    "Language": "ua"
                },
                "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
            })
            this.fetchDepartments(formData).then(() => {
                // this.department = this.departments[0].Description
                this.checkValidData()
            })
        },
        check() {

        },
        checkValidData() {
            const currentTown = this.towns.find(e => e.Description === this.town)
            if (currentTown) {
                this.isValidTown = this.town === currentTown.Description && this.town.length !== 0 && this.building !== '' && this.flat !== ''
            } else {
                this.isValidTown = false
            }

            const currentDepartment = this.departments.find(e => e.Description === this.department)
            if (currentDepartment) {
                this.isValidDepartment = this.department === currentDepartment.Description && this.department.length !== 0
            } else {
                this.isValidDepartment = false
            }

            this.isValid = this.isValidTown && this.isValidDepartment && this.department && this.building && this.flat
            this.changeAddress({town: this.town, department: this.department, building: this.building, flat: this.flat})
            this.$emit('input', this.isValid)
        }
    }
}
