import {mapActions, mapGetters, mapMutations} from "vuex";
import {snakeCase} from 'lodash'
import deliveryNovaPoshta from '../../components/delivery-nova-poshta/index.vue'
import deliveryNovaPoshtaCourier from '../../components/delivery-nova-poshta-courier/index.vue'
import {required, email, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import navigateTo from '../../../../mixins/buy-flow'

export default {
  name: "order",
  mixin: [validationMixin],
  mixins: [navigateTo],
  components: {
    deliveryNovaPoshta,
    deliveryNovaPoshtaCourier
  },
  data() {
    return {
      isAuth: localStorage.getItem('user_token'),
      recipientPhoneError: false,
      recipientNameError: false,
      recipientSecondNameError: false,
      disabled: false,
      selectedDelivery: {
        id: 1
      },
      hidePromocod: true,
      selectedPayment: {},
      callback: false,
      selectedRecipient: {
        id: 0
      },
      recipientList: [
        {
          id: 0,
          title: 'Я'
        },
        {
          id: 1,
          title: this.$t('anotherPerson')
        }
      ],
      certificateFlag: false,
      selectedCertificate: '',
      payload: {
        building: '',
        city: '',
        country: '',
        flat: '',
        recipientName: '',
        recipientPhone: '',
        recipientSecondName: '',
        street: '',
        id: '',
      },
      info: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        additionalInformation: '',

        city: '',
        street: '',
        building: '',
        flat: '',

        recipientFirstName: '',
        recipientLastName: '',
        recipientPhone: '',
      },
      validationErrors: {},
    }
  },
  validations() {
    if (this.selectedDelivery.id === 1) {
      return {
        info: {
          firstName: {
            required
          },
          lastName: {
            required
          },
          phone: {
            required,
            minLength: minLength(17)
          },
          email: {
            email,
            required
          }
        }
      }
    } else if (this.selectedDelivery.id === 3 && this.isAuthenticated) {
      return {
        info: {
          firstName: {required},
          lastName: {required},
          phone: {required, minLength: minLength(17)},
          email: {email,required},
        },
        payload: {
          id: {required}
        }
      }
    } else if (this.selectedDelivery.id === 3 && !this.isAuthenticated) {
      return {
        info: {
          firstName: {required},
          lastName: {required},
          phone: {required, minLength: minLength(17)},
          email: {required, email},
          city: {required},
          street: {required},
          building: {required},
          flat: {required}
        },
      }
    } else if (this.selectedDelivery.id === 2) {
      return {
        info: {
          firstName: {required},
          lastName: {required},
          phone: {required, minLength: minLength(17)},
          email: {required, email},
        },
      }
    } else if (this.selectedDelivery.id === 4) {
      return {
        info: {
          firstName: {required},
          lastName: {required},
          phone: {required, minLength: minLength(17)},
          email: {required, email},
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      deliveryMethods: 'order/deliveryMethods',
      paymentMethods: 'order/paymentMethods',
      basket: 'basket/basket',
      user: 'profile/user',
      isAuthenticated: `auth/isAuthenticated`,
      addressNovaPoshta: `order/address`,
      addressNovaPoshtaCourier: `order/addressCourier`,
      isProfessional: 'profile/isProfessional',
      secondBasket: 'basket/secondBasket',
      //-----------------------------------------------------------------------------
      certificate: 'order/certificate',
      //-----------------------------------payment-----------------------------------
      invoiceResult: 'order/invoiceResult',
      isInvoiceLoading: 'order/isInvoiceLoading',
      transactionResult: 'order/transactionResult',
      isTransactionLoading: 'order/isTransactionLoading',
      orderResult: 'order/orderResult',
      savedAddress: 'order/savedAddress',
      loadingOrder: 'order/isLoading',

    }),
    sumDiscount() {
      return this.secondBasket.reduce((accumulator, currentValue) => {
        if (currentValue.select_type.defaultPrice) {
          return accumulator + (currentValue.select_type.defaultPrice * currentValue.select_count);
        } else {
          return accumulator + (currentValue.select_type.price * currentValue.select_count);
        }

      }, 0)
    },
    firstNameErrors() {
      let error = [];
      if (!this.$v.info.firstName.$dirty) {
        return error;
      }
      if (!this.$v.info.firstName.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.firstName) {
        this.validationErrors.firstName.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.info.phone.$dirty) {
        return error;
      }
      if (!this.$v.info.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.info.phone.minLength) {
        error.push(this.$t('validationMax').replace(':count', 17))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.info.lastName.$dirty) {
        return error;
      }
      if (!this.$v.info.lastName.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.lastName) {
        this.validationErrors.lastName.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.info.email.$dirty) {
        return error;
      }
      if (!this.$v.info.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.info.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    cityErrors() {
      let error = [];
      if (!this.$v.info.city.$dirty) {
        return error;
      }
      if (!this.$v.info.city.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    streetErrors() {
      let error = [];
      if (!this.$v.info.street.$dirty) {
        return error;
      }
      if (!this.$v.info.street.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.street) {
        this.validationErrors.street.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    buildindErrors() {
      let error = [];
      if (!this.$v.info.building.$dirty) {
        return error;
      }
      if (!this.$v.info.building.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.building) {
        this.validationErrors.building.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    flatErrors() {
      let error = [];
      if (!this.$v.info.flat.$dirty) {
        return error;
      }
      if (!this.$v.info.flat.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.flat) {
        this.validationErrors.flat.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    discount() {
      if (this.user && this.user.tempDiscount) {

        return (this.totalSum * 5) / 100
      }
      let sum
      if (this.user && this.user.professional.data && this.user.professional.data.active || this.isProfessional) {
        sum = this.secondBasket.reduce((accumulator, currentValue) => {
          if (currentValue.select_type.defaultPrice) {
            return accumulator + (currentValue.select_type.defaultPrice * currentValue.select_count)
          } else {
            return accumulator + (currentValue.select_type.price * currentValue.select_count)
          }
        }, 0)
        return sum - this.totalSum
      } else {
        return 0
      }
    },
    totalSum() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.select_type.price * currentValue.select_count);
      }, 0);
    },
  },
  mounted() {

    // if (this.isAuthenticated && this.user) {
    //   this.fetchUser().then(() => {
    //     this.info.firstName = this.user.name
    //     this.info.lastName = this.user.secondName
    //     this.info.phone = this.user.phone.replace(/[^+\d]/g, '')
    //     this.info.email = this.user.email
    //   })
    // }
    // console.log(this.info.phone, 'from payload');
  },
  watch: {
    user() {
      this.hidePromocod = false
    },
    'payload.recipientName': function () {
      if (this.payload.recipientName.length > 0) {
        this.$refs.recipientName.textContent = ''
        this.recipientNameError = false
      }

    },
    'payload.recipientPhone': function () {
      if (this.payload.recipientPhone.length > 0) {
        this.$refs.recipientPhone.textContent = ''
        this.recipientPhoneError = false
      }

    },
    'payload.recipientSecondName': function () {
      if (this.payload.recipientSecondName.length > 0) {
        this.$refs.recipientSecondName.textContent = ''
        this.recipientSecondNameError = false
      }

    },
    '$route'(newVal) {
      if (newVal) {
        if (!this.user && this.isAuthenticated) {
          this.fetchUser().then(() => {
            this.setAddress()
          })
        } else {
          this.setAddress()
        }
        this.fetchDeliveryMethods().then(() => {
          this.selectedDelivery = this.deliveryMethods[0]
        })
        this.fetchPaymentMethods().then(() => {
          this.selectedPayment = this.paymentMethods[0]
        })
      }
    },
    'user.addresses.data'(newVal) {
      if (newVal)
        this.setAddress()
    }
  },
  created() {
    this.resetCertificate()
    if (this.isAuthenticated && this.user) {
      this.setAddress()
      this.info.firstName = this.user.name
      this.info.lastName = this.user.secondName
      this.info.phone = this.user.phone.replace(/[^+\d]/g, '')
      this.info.email = this.user.email
    }
    console.log(this.info.email, 'payload');
    this.fetchDeliveryMethods().then(() => {
      this.selectedDelivery = this.deliveryMethods[0]
    })
    this.fetchPaymentMethods().then(() => {
      this.selectedPayment = this.paymentMethods[0]
    })
    this.sync()
  },
  methods: {
    ...mapActions({
      fetchAddress: 'order/GET_ADDRESS_LIST',
      fetchDeliveryMethods: 'order/GET_DELIVERY_METHODS',
      fetchPaymentMethods: 'order/GET_PAYMENT_METHODS',
      fetchUser: 'profile/FETCH_USER_DATA',
      createOrder: 'order/CREATE_ORDER',
      getSync: 'basket/GET_SYNC_BASKET',
      fetchDefaultPrices: 'basket/GET_DEFAULT_PRICE_BASKET',

      fetchCertificate: 'order/GET_CERTIFICATE',

      createInvoice: 'order/CREATE_INVOICE',
      createTransaction: 'order/CREATE_TRANSACTION',
    }),
    ...mapMutations({
      changeAddressesListPopup: 'popups/CHANGE_ADDRESSES_LIST_POPUP',
      resetBasket: 'basket/RESET_BASKET',
      resetCertificate: 'order/CLEAR_CERTIFICATE',
    }),

    getAddress(id) {
      if (id === 3) {
        this.fetchAddress()
        this.changeAddressesListPopup(true)
      }

    },
    checkCode(code) {
      this.fetchCertificate(code).then(() => {
        this.$toasted.success(this.$t('promocodeActivate'))
        if ((((this.selectedDelivery.id === 2) && this.totalSum >= 1500) || ((this.selectedDelivery.id === 3) && this.totalSum >= 3000) || this.selectedDelivery.id === 1) && this.certificate.discount === this.totalSum) {
          this.certificateFlag = true
        } else {
          this.certificateFlag = false
        }
      }).catch(() => {
        this.$toasted.error(this.$t('errorPromocode'))
      })
    },
    setAddress() {
      for (let i in this.user.addresses.data) {
        if (this.user.addresses.data[i].isMain === true) {
          // this.payload.recipientName = this.user.addresses.data[i].recipientName
          // this.payload.recipientSecondName = this.user.addresses.data[i].recipientSecondName
          // this.payload.recipientPhone = this.user.addresses.data[i].recipientPhone
          this.payload.street = this.user.addresses.data[i].street
          this.payload.building = this.user.addresses.data[i].building
          this.payload.city = this.user.addresses.data[i].city
          this.payload.country = this.user.addresses.data[i].country
          this.payload.id = this.user.addresses.data[i].id
          this.payload.flat = this.user.addresses.data[i].flat
        }
      }
    },
    sync() {
      let ids = []
      for (let i in this.basket) {
        ids.push(this.basket[i].id)
      }
      this.getSync({ids: ids})
      this.fetchDefaultPrices({ids: ids})
    },
    createInvoiceLocal() {
      const payloadInvoice = new FormData();
      payloadInvoice.append('order_id', this.orderResult.id);
      this.createInvoice(payloadInvoice).then(() => {
        this.createTransactionLocal();
      }).catch(error => {
        console.log(error);
        if (error.response.status === 422) {
          const objErrors = error.response.data.errors;
          for (let i in objErrors) {
            objErrors[i].forEach(t => {
              this.$toasted.error(t)
            })
          }
        }
      })
    },
    createTransactionLocal() {
      const payloadTransaction = new FormData();
      payloadTransaction.append('invoice_id', this.invoiceResult.id);
      payloadTransaction.append('amount', this.orderResult.amount);

      this.createTransaction(payloadTransaction).then(() => {


      }).catch(error => {
        if (error.response.status === 424) {
          const objErrors = error.response.data;
          this.$toasted.error(objErrors.message);
        }
        if (error.response.status === 422) {
          const objErrors = error.response.data.errors;
          for (let i in objErrors) {
            objErrors[i].forEach(t => {
              this.$toasted.error(t)
            })
          }
        }
      })
    },
    validationPhone() {
      let error = this.$refs.recipientPhone
      let phone = this.payload.recipientPhone.length
      error.textContent = ''
      switch (true) {
        case phone === 0:
          error.append(this.$t('validationRequired'));
          this.recipientPhoneError = true
          break;
        case phone < 16 && phone > 0:
          error.append(this.$t('validationMin').replace(':count', 12));
          this.recipientPhoneError = true
          break;
      }
    },
    validationName() {
      let error = this.$refs.recipientName
      let phone = this.payload.recipientName.length
      error.textContent = ''
      switch (true) {
        case phone === 0:
          error.append(this.$t('validationRequired'));
          this.recipientNameError = true
          break;
        case phone < 2 && phone > 0:
          error.append(this.$t('validationMin').replace(':count', 2));
          this.recipientNameError = true
          break;
      }
    },
    validationSecondName() {
      let error = this.$refs.recipientSecondName
      let phone = this.payload.recipientSecondName.length
      error.textContent = ''
      switch (true) {
        case phone === 0:
          error.append(this.$t('validationRequired'));
          this.recipientSecondNameError = true
          break;
        case phone < 2 && phone > 0:
          error.append(this.$t('validationMin').replace(':count', 2));
          this.recipientSecondNameError = true
          break;
      }
    },
    validationRecipientData() {
      if (this.selectedRecipient.id === 1) {
        this.validationPhone()
        this.validationName()
        this.validationSecondName()
        if (!this.recipientPhoneError && !this.recipientNameError && !this.recipientSecondNameError) {
          this.submit()
        }
      } else {
        this.submit()
      }
    },
    sendOrder() {

      let item = this.addressNovaPoshtaCourier;
      if (this.selectedDelivery.id === 4) {

        !item.department && !item.building && !item.flat ? null : this.validationRecipientData()
      }

      if (this.selectedDelivery.id === 3) {
        if (this.savedAddress.address !== '') {
          this.validationRecipientData()
        } else {
          this.changeAddressesListPopup(true)
        }
      }
      if (this.selectedDelivery.id === 1) {
        this.validationRecipientData()
      }
      if (this.selectedDelivery.id === 2) {
        this.validationRecipientData()
      }
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.info.$invalid) {

        let obj = {}
        let products = []
        for (let i in this.basket) {
          if (this.basket[i].select_type) {
            products.push({
              id: this.basket[i].id,
              product_price_id: this.basket[i].select_type.id,
              count: this.basket[i].select_count
            })
          }
        }
        if (this.selectedDelivery.id === 3) {
          if (this.savedAddress.status === 0) {
            obj.delivery_user_address = this.savedAddress.guid
          }
          if (this.savedAddress.status === 1) {
            obj.delivery_city = this.savedAddress.city
            obj.delivery_user_address = this.savedAddress.street + ',' + this.savedAddress.flat
          }

        }
        for (let i in this.info) {
          obj[snakeCase(i)] = this.info[i]
        }
        console.log(obj.email, 'obj');
        obj.phone = obj.phone.replace(/[^+\d]/g, '')
        obj.delivery_method_id = this.selectedDelivery.id
        if (this.certificate) {
          obj.certificate = this.selectedCertificate
          if (!this.certificateFlag) {
            obj.payment_method_id = this.selectedPayment.id
          }
        } else {
          obj.payment_method_id = this.selectedPayment.id
        }
        obj.products = products
        obj.recipient = this.selectedRecipient.id
        obj.need_confirm = this.callback
        if (this.selectedDelivery.id === 2) {
          if (this.addressNovaPoshta.town && this.addressNovaPoshta.department) {
            obj.delivery_city = this.addressNovaPoshta.town
            obj.delivery_store = this.addressNovaPoshta.department
          }
        } else if (this.selectedDelivery.id === 4) {
          obj.delivery_city = this.addressNovaPoshtaCourier.town
          obj.delivery_user_address = this.addressNovaPoshtaCourier.department + ' ' + this.addressNovaPoshtaCourier.building + ' ' + this.addressNovaPoshtaCourier.flat
        }

        this.createOrder(obj).then(() => {
          if (this.selectedPayment.id === 2) {
            this.createInvoiceLocal()
          } else {
            this.resetBasket()
            this.$router.push({name: 'main'}).catch(() => {
            })
            this.$toasted.success(this.$t('successCreateOrder'));
            setTimeout(function () {
              window.location.reload()
            }, 1000)

          }
        })
      }
    }
  }
}
